<template>
	<div
		id="customer"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<div
			class="modal"
			:class="{ visible: searchingOnSkraseting || searchingOnSona }"
			@click="hideModal()"
		>
			<div class="modal-close"></div>
		</div>

		<h1 class="mb-10">Upplýsingar um fyritøkuna</h1>

		<p class="mb-30 color-grey">Upplýsingar um kundan</p>

		<div class="flex mb-30">
			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="Sp/f"
				id="spf"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="spf"
				>Sp/f</label
			>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="P/f"
				id="pf"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="pf"
				>P/f</label
			>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="fa."
				id="fa"
			/>

			<label
				class="pl-10 mr-20 cursor-pointer"
				for="fa"
			>
				Einstaklingavirki
			</label>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="ÍVF"
				id="ivf"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="ivf"
				>ÍVF</label
			>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="Íognarfelag"
				id="iogn"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="iogn"
				>Íognarfelag</label
			>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="Privat"
				id="private"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="private"
				>Privat</label
			>

			<input
				:disabled="disabled"
				type="radio"
				v-model="companyType"
				value="Annað"
				id="other"
			/>
			<label
				class="pl-10 mr-20 cursor-pointer"
				for="other"
				>Annað</label
			>
		</div>

		<div
			v-if="companyType == 'Annað'"
			class="mb-30"
		>
			<label
				class="block mb-10"
				for="search-input-name"
				>Virkisslag</label
			>

			<input
				ref="companyTypeInput"
				type="text"
				v-model="form.companyType"
			/>
		</div>

		<div class="grid grid-2 grid-gap-x-20">
			<div class="mb-30">
				<!-- TODO: RE ADD SEARCH -->
				<!-- ! --- DISABLED FOR NOW --- -->
				<!-- <label
					class="block mb-5"
					for="search-input-name"
					>Navn (Leita við ENTER)<span -->
				<!-- ! --- DISABLED FOR NOW --- -->
				<label
					class="block mb-5"
					for="search-input-name"
					>Navn<span
						class="color-red"
						style="margin-left: 2.5px"
						>*</span
					></label
				>

				<div
					class="search-input relative"
					:class="{ 'z-100': searchingOnSona }"
				>
					<!-- TODO: RE ADD SEARCH -->
					<input
						:disabled="disabled"
						type="text"
						v-model="form.name"
						@blur="touchInput('name')"
					/>
					<!-- ! --- DISABLE SEARCH FOR NOW --- -->
					<!-- <input
						:disabled="disabled"
						type="text"
						v-model="form.name"
						@blur="touchInput('name')"
						@keydown.enter.prevent="search('sona', form.name)"
						@keydown.esc.prevent="hideModal()"
					/> -->

					<!-- <div
						class="icon-search"
						@click="search('sona', form.name)"
					></div> -->
					<!-- ! --- DISABLE SEARCH FOR NOW --- -->

					<div
						v-if="searchingOnSona"
						class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
						:class="{ 'z-100': searchingOnSona }"
					>
						<a
							href="#"
							class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none"
							v-for="searchResult in searchResults"
							:key="searchResult.name"
							@click.prevent="applySonaSearchResult(searchResult)"
						>
							<p class="mb-5">
								{{ searchResult.contactType != 'PERSON' ? searchResult.lastName : searchResult.firstName + ' ' + searchResult.lastName }}
							</p>

							<p class="color-grey">
								{{ searchResult.streetName }} {{ searchResult.streetNumber }}, {{ searchResult.zip }} {{ searchResult.city }}
							</p>
						</a>
					</div>
				</div>

				<div
					class="small-text color-red mt-5"
					v-if="$v.form.name.$invalid && $v.form.name.$dirty"
				>
					Vinaliga skriva eitt navn
				</div>
			</div>

			<InputGroup
				v-model="form.otherName"
				label="Hjánavn"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20 mb-30">
			<div>
				<label
					class="block mb-10"
					for="search-input-name"
					>Leita hjá Skrásetingini</label
				>

				<div
					class="search-input relative"
					:class="{ 'z-100': searchingOnSkraseting }"
				>
					<input
						:disabled="disabled"
						type="text"
						v-model="searchString"
						@keydown.enter.prevent="search('skraseting', searchString)"
						@keydown.esc.prevent="hideModal()"
					/>

					<div
						class="icon-search"
						@click="search('skraseting', searchString)"
					></div>

					<div
						v-if="searchingOnSkraseting"
						class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
						:class="{ 'z-100': searchingOnSkraseting }"
					>
						<a
							href="#"
							class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none"
							v-for="searchResult in searchResults"
							:key="searchResult.name"
							@click.prevent="applySkrasetingSearchResult(searchResult)"
						>
							<p class="mb-5">{{ searchResult.name }}</p>

							<p class="color-grey">Skrás.nr.: {{ searchResult.registrationNumber }}</p>
						</a>
					</div>
				</div>
			</div>

			<div>
				<label
					class="block mb-10"
					for="search-input-name"
					>Skrásetingarnummar</label
				>

				<div class="search-input relative">
					<input
						:disabled="true"
						type="text"
						v-model="form.registrationNumber"
					/>
				</div>
			</div>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.vtal"
				required
				label="V-tal"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.address"
				required
				label="Bústaður"
			/>

			<InputGroup
				v-model="form.taxationCountry"
				label="Skattskyldugt land"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.zip"
				:required="true"
				label="Postnummar"
				:disabled="disabled"
			/>

			<InputGroup
				v-model="form.city"
				:required="true"
				label="Býur/bygd"
				:disabled="disabled"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.phoneNumber"
				label="Telefon"
				:disabled="disabled"
			/>

			<InputGroup
				v-model="form.email"
				:required="true"
				label="Teldupostur"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.branch"
				label="Vinnugrein"
			/>

			<InputGroup
				v-model="form.swedbankPayReason"
				label="Hvat skal Swedbank Pay brúkast til?"
			/>
		</div>

		<div class="grid grid-2 grid-gap-20">
			<div>
				<label
					class="block mb-10"
					for="vatZone"
					>MVG-sona</label
				>

				<VSelect
					:disabled="disabled"
					v-model="form.vatZone"
					:options="createCustomerInformation.vatZones"
					:optionText="'name'"
					:optionValue="'vatZoneNumber'"
					@input="changeVatZone"
				/>
			</div>

			<div>
				<label
					class="block mb-10"
					for="currency"
					>Gjaldoyra</label
				>

				<VSelect
					:disabled="disabled"
					v-model="form.currency"
					:options="createCustomerInformation.currencies"
					:optionText="'code'"
					:optionValue="'code'"
					@input="changeCurrency"
				/>
			</div>
		</div>

		<div class="mt-20 grid grid-2 grid-gap-20">
			<div>
				<label
					class="block mb-10"
					for="customerGroup"
					>Kundabólkur</label
				>

				<VSelect
					:disabled="disabled"
					v-model="form.customerGroup"
					:options="createCustomerInformation.customerGroups"
					:optionText="'name'"
					:optionValue="'customerGroupNumber'"
					@input="changeCustomerGroup"
				/>
			</div>

			<div>
				<label
					class="block mb-10"
					for="paymentTerm"
					>Gjaldsfreist</label
				>

				<VSelect
					:disabled="disabled"
					v-model="form.paymentTerm"
					:options="createCustomerInformation.paymentTerms"
					:optionText="'name'"
					:optionValue="'paymentTermsNumber'"
					@input="changePaymentTerm"
				/>
			</div>
		</div>

		<div class="mt-20 grid grid-2 grid-gap-20">
			<InputGroup
				v-model="form.mcc"
				label="MCC"
				:disabled="disabled"
			/>
		</div>

		<hr class="mb-20" />

		<div class="add">
			<div class="flex space-between align-center mb-20">
				<div>
					<h2 class="mb-10">
						Fráboðanir
						<span
							class="color-red"
							style="margin-left: 2.5px"
							>*</span
						>
					</h2>

					<p class="color-grey">Her kanst tú skriva trý nummur, sum kunnu fáa fráboðanir frá Terminal.fo</p>
				</div>
			</div>

			<div>
				<div
					v-for="(phoneNumber, index) in form.mobileNumbers"
					class=""
					:key="`phoneNumber-${index}`"
				>
					<input
						v-model="form.mobileNumbers[index]"
						ref="phoneNumberInput"
						type="text"
						@blur="
							() => {
								$v.form.mobileNumbers.$touch();

								if (index == 0) {
									return;
								}

								if (!form.mobileNumbers[index]) {
									form.mobileNumbers.splice(index, 1);
								}
							}
						"
						class="mb-10"
					/>
				</div>

				<div
					class="small-text color-red mb-10 px-5"
					v-if="hasmobileNumbers && $v.form.mobileNumbers.$invalid && $v.form.mobileNumbers.$dirty"
				>
					Vinarliga einans skriva gildig telefonnummur
				</div>
			</div>

			<div
				class="plus flex align-center justify-center py-20 px-30 mb-30 color-blue border-light rounded-lg hover:background-ultra-light-grey-300 cursor-pointer"
				v-show="form.mobileNumbers.length < 3"
				@click="
					() => {
						if (form.mobileNumbers[form.mobileNumbers.length - 1] && form.mobileNumbers.length < 3) {
							form.mobileNumbers.push('');

							$nextTick(() => {
								this.$refs.phoneNumberInput[form.mobileNumbers.length - 1].focus();
							});
						}
					}
				"
			>
				Legg afturat
			</div>
		</div>

		<hr class="my-20" />

		<h2 class="mb-10">TCS</h2>

		<div class="grid grid-2 grid-gap-x-20">
			<InputGroup
				v-model="form.tcs_username"
				label="TCS brúkaranavn"
				:disabled="disabled"
			/>

			<InputGroup
				v-model="form.tcs_email"
				label="TCS teldubústaður"
				:disabled="disabled"
			/>

			<InputGroup
				v-model="form.tcs_phone"
				label="TCS fartelefon"
				:disabled="disabled"
			/>
		</div>
	</div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import VSelect from '@/components/VSelect.vue';
import axios from 'axios';
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators';

export default {
	name: 'Customer',

	props: {
		value: Object,
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		InputGroup,
		VSelect,
	},

	data() {
		return {
			createCustomerInformation: {
				vatZones: [],
				currencies: [],
				paymentTerms: [],
				customerGroups: [],
			},

			searchingOnSkraseting: false,

			searchingOnSona: false,

			searchString: '',

			modalActive: false,

			searchResults: [],

			companyType: 'Sp/f',

			form: {
				id: null,
				companyType: 'Sp/f',
				name: '',
				otherName: '',
				registrationNumber: '',
				address: this.value ? this.value.address : '',
				taxationCountry: 'Føroyar',
				zip: '',
				city: '',
				phoneNumber: '',
				vtal: '',
				email: '',
				branch: '',
				swedbankPayReason: 'Móttaka gjaldskort',
				currencyCode: 'DKK',
				vatZoneNumber: 1,
				customerGroupNumber: 1,
				paymentTermNumber: 1,
				skrasetingResult: {},
				mcc: '',
				tcs_username: '',
				tcs_email: '',
				tcs_phone: '',

				mobileNumbers: [''],
				vatZone: {
					name: 'Domestic',
					vatZoneNumber: 1,
					enabledForCustomer: true,
					enabledForSupplier: true,
					self: 'https://restapi.e-conomic.com/vat-zones/1',
				},
				currency: {
					name: 'Danske kroner',
					code: 'DKK',
					isoNumber: '208',
					self: 'https://restapi.e-conomic.com/currencies/DKK',
				},
				paymentTerm: {
					paymentTermsNumber: 1,
					daysOfCredit: 8,
					description: 'Netto 8 dage',
					name: 'Netto 8 dage',
					paymentTermsType: 'net',
					self: 'https://restapi.e-conomic.com/payment-terms/1',
				},
				customerGroup: {
					customerGroupNumber: 1,
					name: 'Kundar',
					customers: 'https://restapi.e-conomic.com/customer-groups/1/customers',
					self: 'https://restapi.e-conomic.com/customer-groups/1',
				},
			},
		};
	},

	validations: {
		form: {
			name: {
				required,
				minLength: minLength(2),
			},

			email: {
				required,
				email,
			},

			mobileNumbers: {
				maxLength: maxLength(50),
			},

			vtal: {
				required,
			},

			address: {
				required,
			},

			zip: {
				required,
			},

			city: {
				required,
			},
		},
	},

	watch: {
		companyType: function (companyType) {
			if (companyType == 'Annað') {
				if (['Sp/f', 'P/f', 'fa.', 'ÍVF', 'Íognarfelag'].includes(this.form.companyType)) {
					this.form.companyType = '';
				}

				return;
			}

			this.form.companyType = companyType;
		},

		'$v.$invalid': function () {
			this.$emit('validStatusChanged', this.$v.$invalid);
		},

		value: {
			handler(customer) {
				this.form.id = customer.id;
				this.form.name = customer.name;
				this.form.otherName = customer.otherName;
				this.form.registrationNumber = customer.registrationNumber;
				this.form.address = customer.address;
				this.form.zip = customer.zip;
				this.form.city = customer.city;
				this.form.email = customer.email;
				this.form.phoneNumber = customer.phoneNumber;
				this.form.vtal = customer.vtal;
				this.form.currencyCode = customer.currencyCode;
				this.form.vatZoneNumber = customer.vatZoneNumber;
				this.form.customerGroupNumber = customer.customerGroupNumber;
				this.form.paymentTermNumber = customer.paymentTermNumber;
				this.form.economicId = customer.economicId;
				this.form.mcc = customer.mcc;
				this.form.tcs_username = customer.tcs_username;
				this.form.tcs_email = customer.tcs_email;
				this.form.tcs_phone = customer.tcs_phone;

				this.form.companyType = customer.companyType;

				this.companyType = ['Sp/f', 'P/f', 'fa.', 'ÍVF', 'Íognarfelag'].includes(customer.companyType) ? customer.companyType : 'Annað';

				if (typeof customer.mobileNumbers == 'string' && customer.mobileNumbers.length) {
					this.form.mobileNumbers = customer.mobileNumbers.split(',');
				}

				this.form.mobileNumbers = customer.mobileNumbers ?? [''];
			},
		},

		form: {
			immediate: true,

			deep: true,

			handler(value) {
				this.$emit('input', value);
			},
		},
	},

	mounted() {
		this.getCreateCustomerInformationFromEconomic();
	},

	computed: {
		hasmobileNumbers() {
			for (const phoneNumber of this.form.mobileNumbers) {
				if (phoneNumber) {
					return true;
				}
			}

			return false;
		},
	},

	methods: {
		changeVatZone(vatZone) {
			this.form.vatZoneNumber = vatZone.vatZoneNumber;
		},

		changeCurrency(currency) {
			this.form.currencyCode = currency.code;
		},

		changePaymentTerm(paymentTerm) {
			this.form.paymentTermNumber = paymentTerm.paymentTermsNumber;
		},

		changeCustomerGroup(customerGroup) {
			this.form.customerGroupNumber = customerGroup.customerGroupNumber;
		},

		getCreateCustomerInformationFromEconomic() {
			axios
				.get('/customers/economic/create-customer-information')
				.then((response) => {
					this.createCustomerInformation = response.data;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},

		touchInput(element) {
			this.$v.form[element].$touch();
		},

		applySonaSearchResult(searchResult) {
			if (searchResult.contactType == 'PERSON') {
				this.form.name = searchResult.firstName + ' ' + searchResult.lastName;
			}

			if (searchResult.contactType != 'PERSON') {
				// Most likely a company. User lastName
				this.form.name = searchResult.lastName;
			}

			this.form.address = searchResult.streetName + ' ' + searchResult.streetNumber;
			this.form.city = searchResult.city;
			this.form.email = searchResult.email;
			this.form.zip = searchResult.zip;

			this.form.phoneNumber = searchResult.phoneNumbers[0].phoneNumber;

			this.hideModal();
		},

		applySkrasetingSearchResult(searchResult) {
			this.form.skrasetingResult = searchResult;

			this.form.registrationNumber = searchResult.registrationNumber;

			this.hideModal();
		},

		async search(searchEngine, searchString) {
			this.searchResults = [];

			// document.querySelector(`#search-${searchEngine}-input-name`).focus();

			if (searchEngine == 'skraseting') {
				this.searchingOnSkraseting = true;
				this.searchingOnSona = false;
			}

			if (searchEngine == 'sona') {
				this.searchingOnSkraseting = false;
				this.searchingOnSona = true;
			}

			await axios
				.get(`/customers/${searchEngine}/${searchString}`)
				.then((response) => {
					this.searchResults = response.data;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},

		hideModal() {
			this.searchingOnSkraseting = false;
			this.searchingOnSona = false;
		},
	},
};
</script>
